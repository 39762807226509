import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FurtherReading from "../components/furtherReading"

const CutSplitEnds = () => (
  <Layout>
    <SEO
      title="Cut off Split Ends for Faster Natural Hair Growth"
      desciption="Cutting your hair might seem like a bad way to help your hair grow. You want it to grow so why would you make it shorter? Well, there’s a good reason"
    />
    <h1>Cut off Split Ends for Faster Natural Hair Growth</h1>

    <p>Cutting your hair might seem like a bad way to help your hair grow. You want it to grow so why would you make it shorter? Well, there’s a good reason you’ve probably heard it recommended so many times and I’m sure you’ll be converted if you are on the fence by the time you reach the end of this post.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/9XYqS0F.jpg"
        alt="how often you should wash your hair"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Photo credit: <a
        href="https://unsplash.com/@alex_gruber"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >Alex Gruber</a></p>
    </div>

    <p>The length our hair can grow in general is largely determined by our genetics. Environmental factors affect how close we can get to this length. Some of us can get long hair seemingly no matter how we treat our hair, good or bad. While others have short hair no matter how hard we try. It doesn’t matter which group you fall into, you probably aren’t reaching your maximum potential.</p>
    <p>Imagine you cut your hair down to nothing. As new hair grows, if you looked under a microscope; it would be strong, healthy and filled with promise. As it kept growing and reached medium length. Under the microscope again, the roots would be completely fine but you might notice a slightly different state near the ends of the hair. It might be slightly thinner or more frayed. Then when your hair starts getting long, you might see a significant difference between the tips of your hair and the roots.</p>
    <p>The tips might be very thin, very frayed, even splitting. You will likely see clear damage if you haven’t been caring for your hair or your hair hasn’t responded well to the care you have been giving it.</p>
    <p>This damage happens for all sorts of reasons. I don’t want to go too much into why it gets damaged because I’m sure you can imagine why. Extreme heat or cold, direct harsh sunlight, rough fabrics, dehydration and anything else you can think of.</p>

    <h2>What Trimming Does</h2>
    <p>Now that we are all caught up on how we get to the point where you need to trim your hair. What does trimming actually do? The idea is that when you trim your hair, you are getting rid of the damaged hair and minimising the potential spread of the damage.</p>
    <p>A damaged end doesn’t just sit in one spot contently, it continues along the strand of hair. Cutting off the damaged section gives a nice tidy end and means the damage can’t spread. In a way it’s like cutting your hair off but keeping most of your length.</p>
    <p>To help visualise the spread of damage. Imagine a piece of paper. Pick it up and shake it around roughly. Now put it down, what do you see? Probably just some creases. If you tore it, get some help. Next, pick it up again and tear it to around 3/4 the way through and make a pencil mark. Now lift it and shake it around just as rough as you did the first time. Examine it again and you should notice the tear has grown.</p>
    <p>To explain, something that is damaged will easily become more damaged under the same conditions when compared to something that isn’t damaged. If you cut out the damaged bit and shook the paper again, that is essentially trimming your hair.</p>

    <h2>How Often to Trim and How Much</h2>
    <p>There is no set rule to trimming your ends, it all depends on you as an individual. Looking after your hair will extend the time you take between trims and don’t expect trimming alone to solve all of your problems, it’s a combined effort from different parts of your whole routine.</p>
    <p>When you think about when to trim your hair and how much to trim off. You want to do it at a rate where the new growth will overtake what you are trimming off. Imagine cutting off 1 inch every time your hair has only grown half an inch. That equals half an inch of less hair every time you trim. If you are cutting off half an inch every time your hair has grown an inch then you will notice half an inch of growth on trim days.</p>
    <p>Manage your expectations because eventually your hair will reach a point where it isn’t growing any longer and your work will be for maintenance only. Don’t stop trimming though because the ends will get damaged and that damage will cause your hair to shorten.</p>

    <h2>Salon or Trim Yourself</h2>
    <p>When you are first starting to trim keep a record of your hair condition and the length. Don’t just go to the salon and ask for a trim then expect them to sort everything out for you. They won’t. It’s up to you to track what’s going on. At least with a record, you can see what’s going on, then maybe in the future you can graduate to trimming your own hair.</p>



    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}



      <FurtherReading
      content={
        <div>
        <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>  
        <p>
            <Link to="/14-tips-on-how-to-grow-afro-hair/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            14 tips on how to grow afro hair
            </Link>{" "}
            - Growing afro hair can be one of the most annoying things in the world, the good news is that growing natural doesn't have to be hard. Read our 14 tips to make your life easier here...
          </p>             
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default CutSplitEnds
